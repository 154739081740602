<template>
  <div>
    <el-dialog
      :visible="visible"
      class="dialog"
      :close-on-click-modal="false"
      :before-close="onBeforeClose"
    >
      <div class="login-container">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          size="default"
          autocomplete="on"
          label-position="left"
        >
          <div class="title-container">
            <h3 class="title">注册</h3>
            <!-- <lang-select class="set-language" /> -->
          </div>

          <el-form-item prop="username" :error="usernameError">
            <span class="svg-container">
              <i class="el-icon-message" />
            </span>
            <el-input
              v-model="loginForm.username"
              placeholder="邮箱"
              name="username"
              type="text"
              autocomplete="on"
            />
          </el-form-item>

          <el-form-item prop="code" :error="codeError">
            <span class="svg-container">
              <i class="el-icon-message" />
            </span>
            <el-input
              v-model="loginForm.code"
              placeholder="邮箱验证码"
              name="code"
              type="text"
              maxlength="6"
            />
            <el-button type="text" style="margin-right: 10px" @click="sendCode">
              获取验证码
            </el-button>
          </el-form-item>

          <el-tooltip v-model="capsTooltip" content="Caps lock 已开启" placement="right" manual>
            <el-form-item prop="password">
              <span class="svg-container">
                <i class="el-icon-lock" />
              </span>
              <el-input
                :key="passwordType"
                ref="password"
                v-model="loginForm.password"
                :type="passwordType"
                placeholder="密码（8-20个字符）"
                name="password"
                autocomplete="on"
                @keyup.native="checkCapslock"
                @blur="capsTooltip = false"
                @keyup.enter.native="handleLogin"
              />
              <span class="show-pwd" @click="showPwd">
                <i :class="passwordType === 'password' ? 'el-icon-view' : 'el-icon-unlock'" />
              </span>
            </el-form-item>
          </el-tooltip>
          <el-form-item prop="password2">
            <span class="svg-container">
              <i class="el-icon-lock" />
            </span>
            <el-input
              :key="passwordType"
              v-model="loginForm.password2"
              :type="passwordType"
              placeholder="请再次输入密码"
              name="password"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <i :class="passwordType === 'password' ? 'el-icon-view' : 'el-icon-unlock'" />
            </span>
          </el-form-item>

          <el-button
            :loading="loading"
            type="primary"
            size="default"
            style="width: 100%; margin-bottom: 10px"
            @click.native.prevent="handleLogin"
          >
            注册
          </el-button>

          <div class="footer">
            已有账号，
            <el-button type="text" @click="showLogin">直接登录</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showCaptcha" class="dialog-captcha">
      <el-form
        ref="captchaForm"
        :model="captchaForm"
        :rules="captchaRule"
        size="default"
        label-width="0px"
      >
        <el-form-item prop="code" :error="captchaError">
          <el-input v-model="captchaForm.code" maxlength="4" />
          <el-image :src="captchaUrl" style="width: 250px" />
          <el-button type="text" @click="refreshCaptcha">刷新</el-button>
        </el-form-item>

        <el-button
          type="primary"
          size="default"
          style="width: 100%; margin-bottom: 10px"
          @click.native.prevent="handleSendMail"
        >
          发送验证码
        </el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
import User from './index.js'
import { API_HOST } from './config.js'

const CAPTCHA_API = `${API_HOST}/api/captcha`

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validatePassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
        return
      }
      if (value !== this.loginForm.password) {
        callback(new Error('密码输入不一致'))
        return
      }

      callback()
    }

    return {
      loginForm: {
        username: '',
        code: '',
        password: '',
        password2: '',
      },
      loginRules: {
        username: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '邮箱格式不正确', trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, max: 20, message: '密码长度在8-20个字符', trigger: 'blur' },
        ],
        password2: [
          {
            required: true,
            message: '请再次输入密码',
            trigger: 'blur',
          },
          { trigger: 'blur', validator: validatePassword2 },
        ],
      },
      captchaForm: {
        code: '',
      },
      captchaRule: {
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 4, message: '验证码错误', trigger: 'blur' },
        ],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      usernameError: '',
      codeError: '',

      showCaptcha: false,
      captchaUrl: '',
      captchaError: '',
    }
  },

  methods: {
    onBeforeClose() {
      this.action = 'close'
      this.doClose()
    },
    doClose() {
      if (!this.visible) return
      this.visible = false
      this._closing = true

      this.onClose && this.onClose()
      // messageBox.closeDialog(); // 解绑
      if (this.lockScroll) {
        setTimeout(this.restoreBodyStyle, 200)
      }
      this.opened = false
      // this.doAfterClose();
      setTimeout(() => {
        if (this.action) this.callback(this.action, this)
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    showLogin() {
      this.doClose()
      User.showLogin()
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    sendCode() {
      this.$refs.loginForm.validateField(['username'], async err => {
        if (!err) {
          this.refreshCaptcha()
          this.showCaptcha = true
        }
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          this.usernameError = ''
          this.codeError = ''

          const res = await fetch(`${API_HOST}/api/user/register`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
            body: qs.stringify(this.loginForm),
          })
            .then(req => req.json())
            .catch(err => {
              console.warn(err)
            })

          this.loading = false

          if (res?.code === 0) {
            this.$alert('注册成功，请登录', '恭喜', {
              type: 'success',
              center: true,
              confirmButtonText: '确定',
              callback: () => {
                this.action = res
                this.showLogin()
              },
            })
          } else if (res?.code === -2) {
            this.codeError = '验证码错误'
          } else if (res?.code === -4) {
            this.codeError = '此邮箱不被允许注册'
          } else if (res?.code === -3) {
            this.usernameError = '邮箱已注册'
          } else {
            console.warn(res)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    refreshCaptcha() {
      this.captchaUrl = CAPTCHA_API + '?' + Date.now()
    },
    handleSendMail() {
      this.$refs.captchaForm.validate(async valid => {
        if (valid) {
          this.captchaError = ''
          this.codeError = ''

          const res = await fetch(`${API_HOST}/api/sendmail`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
            body: qs.stringify({ to: this.loginForm.username, captcha: this.captchaForm.code }),
          })
            .then(req => req.json())
            .catch(err => {
              console.warn(err)
            })

          if (res?.code === 0) {
            this.showCaptcha = false
          } else if (res?.code === -2) {
            this.captchaError = '验证码错误'
          } else if (res?.code === -4) {
            this.codeError = '此邮箱不被允许注册'
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
$bg: #fff; //#283443;
$light_gray: #606266; // #fff;
$cursor: #606266;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px rgba(243, 243, 243, 1) inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: #454545;

    .el-form-item__content {
      display: flex;
      align-items: center;
    }
  }
}
.dialog-captcha {
  .el-dialog {
    width: 300px;
  }

  .el-form-item__content {
    display: flex;
    align-items: center;
  }
}
.el-message-box {
  width: 300px;
}
</style>

<style lang="scss" scoped>
$bg: #fff; //#2d3a4b;
$dark_gray: #889aa4;
$light_gray: #303133; //#eee;

.dialog {
  ::v-deep .el-dialog {
    background-color: $bg;
    width: 350px;
  }
}

.login-container {
  min-height: 100%;
  width: 100%;
  // background-color: $bg;
  overflow: hidden;
  margin-top: -20px;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    // padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    flex: 0 0 40px;
    display: inline-block;
    i {
      font-size: 1.3em;
    }
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .footer {
    color: #fff;
    font-size: 12px;
    text-align: right;
  }
}
</style>
