import Vue from 'vue'
import Element from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './element-variables.scss'

import { API_HOST } from './config.js'
import Login from './Login'
import Register from './Register'

Vue.use(Element, {})

export default {
  showLogin(callback) {
    Login.showLogin({ callback })
  },
  showRegister(callback) {
    Register.showRegister({ callback })
  },
  async getUserInfo(callback) {
    const res = await fetch(`${API_HOST}/api/user/getuserinfo`, {
      credentials: 'include',
    })
      .then(req => req.json())
      .catch(err => {
        console.warn(err)
      })

    callback(
      res || {
        code: -500,
        msg: '请求失败',
      }
    )
  },
  async logout(callback) {
    const res = await fetch(`${API_HOST}/api/user/logout`, {
      credentials: 'include',
    })
      .then(req => req.json())
      .catch(err => {
        console.warn(err)
      })

    callback(
      res || {
        code: -500,
        msg: '请求失败',
      }
    )
  },
}
