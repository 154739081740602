<template>
  <el-dialog
    :visible="visible"
    class="dialog"
    :close-on-click-modal="false"
    :before-close="onBeforeClose"
  >
    <div class="login-container">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        size="default"
        autocomplete="on"
        label-position="left"
      >
        <div class="title-container">
          <h3 class="title">登录</h3>
          <!-- <lang-select class="set-language" /> -->
        </div>

        <el-form-item prop="username" :error="usernameError">
          <span class="svg-container">
            <!-- <i class="el-icon-user-solid" /> -->
            <i class="el-icon-message" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="账号"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="Caps lock 已开启" placement="right" manual>
          <el-form-item prop="password" :error="passwordError">
            <span class="svg-container">
              <i class="el-icon-lock" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="密码"
              name="password"
              tabindex="2"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <i :class="passwordType === 'password' ? 'el-icon-view' : 'el-icon-unlock'" />
            </span>
          </el-form-item>
        </el-tooltip>

        <el-button
          :loading="loading"
          type="primary"
          size="default"
          style="width: 100%; margin-bottom: 10px"
          @click.native.prevent="handleLogin"
        >
          登录
        </el-button>

        <div class="footer">
          <el-button type="text" @click="showRegister">快速注册</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import qs from 'qs'
import User from './index.js'
import { API_HOST } from './config.js'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'))
        return
      }

      callback()
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
        return
      }

      callback()
    }
    return {
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      usernameError: '',
      passwordError: '',
    }
  },

  methods: {
    onBeforeClose() {
      this.action = 'close'
      this.doClose()
    },
    doClose() {
      if (!this.visible) return
      this.visible = false
      this._closing = true

      this.onClose && this.onClose()
      // messageBox.closeDialog(); // 解绑
      if (this.lockScroll) {
        setTimeout(this.restoreBodyStyle, 200)
      }
      this.opened = false
      // this.doAfterClose();
      setTimeout(() => {
        if (this.action) this.callback(this.action, this)
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    showRegister() {
      this.doClose()
      User.showRegister()
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          this.usernameError = ''
          this.passwordError = ''

          const res = await fetch(`${API_HOST}/api/user/login`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
            body: qs.stringify(this.loginForm),
          })
            .then(req => req.json())
            .catch(err => {
              console.warn(err)
              this.passwordError = '登录失败，请稍后再试'
            })

          this.loading = false

          if (res?.code === 0) {
            this.action = res
            this.doClose()
          } else if (res?.code === -11) {
            this.usernameError = res.msg
          } else if (res?.code === -12) {
            this.passwordError = res.msg
          } else {
            res && console.warn(res)
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss">
$bg: #fff; //#283443;
$light_gray: #606266; // #fff;
$cursor: #606266;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px rgba(243, 243, 243, 1) inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #fff; //#2d3a4b;
$dark_gray: #889aa4;
$light_gray: #303133; //#eee;

.dialog {
  ::v-deep .el-dialog {
    background-color: $bg;
    width: 350px;
  }
}

.login-container {
  min-height: 100%;
  width: 100%;
  // background-color: $bg;
  overflow: hidden;
  margin-top: -20px;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    // padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    flex: 0 0 40px;
    display: inline-block;
    i {
      font-size: 1.3em;
    }
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .footer {
    color: #fff;
    font-size: 12px;
    text-align: right;
  }
}
</style>
